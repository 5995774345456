
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  useContext,
} from '@nuxtjs/composition-api';
import { getOptimizedImage } from '~/helpers/getOptimizedImage';
import MainContactForm from '~/domains/main/components/MainContactForm';

export default defineComponent({
  name: 'MainContactSection',
  components: { MainContactForm },
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const contactSection = ref();
    const titleBlock = ref();
    const formBlock = ref();
    const modal = ref();
    const { $gsap, i18n } = useContext();
    const isFormSuccess = ref(false);

    const modalTitle = computed(() =>
      isFormSuccess.value
        ? i18n.t('successfully_sent')
        : i18n.t('something_went_wrong')
    );
    const modalDescription = computed(() =>
      isFormSuccess.value
        ? i18n.t('successfully_sent_description')
        : i18n.t('please_try_again_later')
    );

    const animateOnScroll = () => {
      const instance = $gsap.matchMedia();
      instance.add('(min-width: 1024px)', () => {
        $gsap.fromTo(
          titleBlock.value,
          {
            y: 190,
          },
          {
            y: 0,
            ease: 'power3.out',
            scrollTrigger: {
              scrub: 1,
              trigger: contactSection.value.$el,
              start: '-10% center',
              end: '70% center',
              toggleActions: 'restart none none reverse',
            },
          }
        );

        $gsap.fromTo(
          formBlock.value.$el,
          {
            y: 70,
          },
          {
            y: 0,
            ease: 'power3.out',
            scrollTrigger: {
              scrub: 1,
              trigger: contactSection.value.$el,
              start: '-10% center',
              end: '70% center',
              toggleActions: 'restart none none reverse',
            },
          }
        );
      });

      instance.add('(max-width: 1023px)', () => {
        $gsap.fromTo(
          titleBlock.value,
          {
            y: 80,
          },
          {
            y: -30,
            ease: 'power3.out',
            scrollTrigger: {
              scrub: 0.5,
              trigger: contactSection.value.$el,
              start: '-10% 70%',
              end: '70% center',
              toggleActions: 'restart none none reverse',
            },
          }
        );

        $gsap.fromTo(
          formBlock.value.$el,
          {
            y: 100,
          },
          {
            y: -20,
            ease: 'power3.out',
            scrollTrigger: {
              scrub: 1,
              trigger: contactSection.value.$el,
              start: '-10% 70%',
              end: '70% center',
              toggleActions: 'restart none none reverse',
            },
          }
        );
      });
    };

    onMounted(() => {
      animateOnScroll();
    });

    return {
      modal,
      contactSection,
      titleBlock,
      formBlock,
      isFormSuccess,
      modalTitle,
      modalDescription,
      getOptimizedImage,
    };
  },
});
