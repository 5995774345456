
import { ref, defineComponent, provide } from '@nuxtjs/composition-api';
import useForm from '@/composables/useForm';

const FORM_FIELDS_PROTOTYPE = {
  name: '',
  country: '',
  email: '',
  description: '',
  agreement: false,
  token: '',
};

const FORM_TYPE = {
  type: 'lorgar_contact',
};

export default defineComponent({
  name: 'MainContactForm',
  props: {
    isFormSuccess: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const recaptchaError = ref(false);
    const isLoading = ref(false);
    const { formFields, submit, resetForm } = useForm({
      ...FORM_FIELDS_PROTOTYPE,
      ...FORM_TYPE,
    });

    const isButtonDisabled = (validatorInvalid) =>
      validatorInvalid ||
      isLoading.value ||
      !formFields.value.agreement ||
      !formFields.value.token;

    const handleForm = async (resetValidation) => {
      isLoading.value = true;

      if (!formFields.value.token) {
        recaptchaError.value = true;
        isLoading.value = false;
        return;
      }
      try {
        await submit(formFields.value);
        emit('update:isFormSuccess', true);
      } catch (_) {
        emit('update:isFormSuccess', false);
      } finally {
        isLoading.value = false;
        emit('send');
        resetForm(resetValidation);
        resetValidation();
      }
    };

    provide('recaptchaError', recaptchaError);

    return {
      formFields,
      isLoading,
      handleForm,
      isButtonDisabled,
    };
  },
});
