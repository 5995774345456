
import { defineComponent, ref } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'BaseModal',
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },
  setup() {
    const modal = ref(null);

    return {
      modal,
    };
  },
});
